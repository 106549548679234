import PropTypes from 'prop-types';
import React from 'react';
import { graphql } from 'gatsby';
import { Trans } from 'gatsby-plugin-react-i18next';

import Layout from '../components/Layout';
import Hero from '../components/Hero';
import Button from '../components/Button';
import reserveBackground from '../images/reserve.jpg';

import { reserve } from '../css/Reserve.module.css';

const Reserve = ({ data }) => {
  const { wpWebdata } = data;
  const attributes = wpWebdata;
  const email = 'info@shibuya.nl';

  return (
    <Layout pageTitle={attributes.shibuya.titleReserve} copyright={attributes.shibuya.copyright}>
      <Hero backgroundImage={reserveBackground} attributes={attributes} />
      <article className={reserve}>
        <h1>{attributes.shibuya.reserve}</h1>
        <p>{attributes.shibuya.reserveLine1}</p>
        <p>{attributes.shibuya.reserveLine2}</p>
        <p>
          <Trans i18nKey={attributes.shibuya.reserveLine3} email={email}>
            {attributes.shibuya.reserveLine3}
          </Trans>
        </p>
        <p>{attributes.shibuya.reserveLine4}</p>
        <a href="#ft-open">
          <Button labelText={attributes.shibuya.bookNow} />
        </a>
      </article>
    </Layout>
  );
};

export default Reserve;

Reserve.propTypes = {
  data: PropTypes.shape({
    wpWebdata: PropTypes.shape({
      title: PropTypes.string,
      shibuya: PropTypes.shape({
        titleHome: PropTypes.string,
        titleAboutUs: PropTypes.string,
        titleMenu: PropTypes.string,
        titleReserve: PropTypes.string,
        titleFaq: PropTypes.string,
        titleContact: PropTypes.string,
        reserve: PropTypes.string.isRequired,
        reserveLine1: PropTypes.string.isRequired,
        reserveLine2: PropTypes.string.isRequired,
        reserveLine3: PropTypes.string.isRequired,
        reserveLine4: PropTypes.string.isRequired,
        bookNow: PropTypes.string.isRequired,
        copyright: PropTypes.string,
      }).isRequired,
    }).isRequired,
  }).isRequired,
};

export const query = graphql`
query ($language: String!) {
  wpWebdata(title: {eq: $language}) {
    title
    shibuya {
      titleHome
      titleAboutUs
      titleMenu
      titleReserve
      titleFaq
      titleContact
      reserve
      reserveLine1
      reserveLine2
      reserveLine3
      reserveLine4
      bookNow
      copyright
    }
  }
}
`;
